import Typography from "typography"

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHeight: 1.3,
  headerFontFamily: ["Oranienbaum", "sans-serif"],
  bodyFontFamily: ["Advent Pro", "serif"],
  googleFonts: [
  {
    name: 'Oranienbaum',
    styles: [
      '300',
      '400',
      '500',
      '600',
      '700'
    ],
  },
  {
    name: 'Advent Pro',
    styles: [
      '300',
      '400',
      '500',
      '600',
      '700'
    ],
  },
  {
    name: 'Stint Ultra Condensed',
    styles: [
      '300',
      '400',
      '500',
      '600',
      '700'
    ],
  }
]
})

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
