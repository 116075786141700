import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import paris from '../images/paris.jpg'
import destinationparis from '../images/destination-paris.jpg'

// console.log(paris)
// console.log(destinationparis)

const Header = ({ siteTitle, menuLinks }) => (
  <header>
    <div>
      <h1>Destination <span>Paris</span></h1>
    </div>
    <nav>
      <h2>Navigation</h2>
      <ul>
      {
        menuLinks.map(link =>
          <li key={link.name}>
            <Link to={link.link}>{link.name}</Link>
          </li>)
      }
      </ul>
    </nav>
  </header>

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
