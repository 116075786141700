import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import mapboxgl from 'mapbox-gl'

import { withPrefix } from 'gatsby'

class IndexPage extends React.Component {
  createMap() {
    const zoom = 11
    const coordinates = [2.335255, 48.861122]
    const map = new mapboxgl.Map({
      center: coordinates,
      container: 'map',
      style: 'mapbox://styles/serith/cjtzo7w0z0pae1fmkq32r9x14',
      zoom: zoom,
    })

    map.on('load', function() {
      map.loadImage(withPrefix('static/star.png'), function(error, image) {
        if (error) throw error;
        map.addImage('star', image);
        map.addLayer({
            "id": "places",
            "type": "symbol",
            "source": {
                "type": "geojson",
                "data": {
                    "type": "FeatureCollection",
                    "features": [
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Arc de Triomphe",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.295026, 48.873781],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Basilica of the Sacré Cœur",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.343097, 48.886335],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Centre Georges Pompidou",
                          "description": "We didn't have time for this.",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.352245, 48.860642],
                          }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Eiffel Tower",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.294254, 48.858278],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "La Conciergerie",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.345832, 48.855470],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "La Geode",
                          "description": "We didn't have time for this.",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.388675, 48.894534],
                          }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "La Sainte-Chapelle",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.344987, 48.855420],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Les Catacombes",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.332404, 48.833953],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Louvre",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.335255, 48.861122],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Moulin Rouge",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.332267, 48.884060],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Musée d’Orsay",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.325481, 48.860367],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Musée des Vampires",
                          "description": "We had to contact the owner, which we did via email before our visit. We did not get a response so we were unable to see this.",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.414527, 48.875702],
                          }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Notre Dame",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.349902, 48.852968],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Père Lachaise Cemetery",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.389337, 48.860010],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Place de la Bastille",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.369129, 48.853158],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Shakespeare and Company",
                          "description": "",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.347093, 48.852580],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Statue of Liberty",
                          "description": "We didn’t have time for this, but was able to get a glimpse of it from the metro.",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.27969,  48.850019],
                        }
                    },
                    {
                        "type": "Feature",
                        "properties": {
                          "title": "Stravinsky Fountain",
                          "description": "We didn't have time for this.",
                        },
                        "geometry": {
                          "type": "Point",
                          "coordinates": [2.351479, 48.859499],
                        }
                    }
                  ]
                }
            },
            "layout": {
              "icon-image": "star",
              "icon-size": 1
            }
        });
      });
    });
    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl());

    map.on('click', 'places', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var title = e.features[0].properties.title;
      var description = e.features[0].properties.description;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML('<h4>' + title + '</h4>' + '<p>' + description + '</p>')
      .addTo(map);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'places', function () {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'places', function () {
      map.getCanvas().style.cursor = '';
    });
  }

  componentDidMount() {
    mapboxgl.accessToken ='pk.eyJ1Ijoic2VyaXRoIiwiYSI6ImNqcWp5emV5bTBsdHc0M290MzZ4MDkwbG0ifQ.QbArrXJB51b8afXwJxZhVQ'

    this.createMap()
  }

  render() {
    return (
      <Layout>
      <SEO title="Map" />

      <section className='mapPage'>
        <header>
          <h1>Map</h1>
        </header>

        <div id='map' className="map"></div>
        <ul>
          <li>Arc de Triomphe</li>
          <li>Basilica of the Sacré Cœur</li>
          <li>Centre Georges Pompidou</li>
          <li>Eiffel Tower</li>
          <li>La Conciergerie</li>
          <li>La Geode</li>
          <li>La Sainte-Chapelle</li>
          <li>Les Catacombes</li>
          <li>Louvre</li>
          <li>Moulin Rouge</li>
          <li>Musée d’Orsay</li>
          <li>Musée des Vampires</li>
          <li>Notre Dame</li>
          <li>Père Lachaise Cemetery</li>
          <li>Place de la Bastille</li>
          <li>Shakespeare and Company</li>
          <li>Statue of Liberty</li>
          <li>Stravinsky Fountain</li>
        </ul>
      </section>


      </Layout>
    )
  }
}

export default IndexPage
