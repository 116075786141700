import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
//import './layout.css'
import './custom.css'
import { rhythm } from '../utils/typography'


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
            menuLinks {
             name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <div className='page'>
      <div className='flex-container'>
        <Header menuLinks={data.site.siteMetadata.menuLinks} siteTitle={data.site.siteMetadata.title} />
        <div className='flex-container'
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            //padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            marginBottom: rhythm(1 / 4),
          }}
        >
          <aside>
            <h1 style={{margin: `0 auto`, fontWeight:'400'}}>{data.site.siteMetadata.description}</h1>
          </aside>
          <main>
            {children}
          </main>
        </div>
      </div>
      <footer>
        <p>© 2013-{new Date().getFullYear()} <a href="https://mattbayliss.co.uk" target="_blank" rel="noopener noreferrer">Matt Bayliss</a>. All rights reserved.</p>
      </footer>
    </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
